import { Injectable } from '@angular/core';

import { getAppConfig } from 'src/app/utils/utils';
import { environment } from 'src/environments/environment';
import { SlackChannel } from 'src/models';

import { ApiService } from './api.service';
import { AuthService } from './auth.service';

function formatChannelName(c: SlackChannel) {
  return `${c.name} (${c.isPrivate ? 'private' : 'public'})`;
}

@Injectable({
  providedIn: 'root',
})
export class SlackService {
  clientId = environment.slack.clientID;
  appId = environment.slack.appID;

  constructor(
    private authService: AuthService,
    private apiService: ApiService,
  ) {
    const appConfig = getAppConfig();

    if (appConfig.slackClientId) {
      this.clientId = appConfig.slackClientId;
    }

    if (appConfig.slackAppId) {
      this.appId = appConfig.slackAppId;
    }
  }

  cachedScopes: Record<string, boolean>;
  cachedUserScopes: Record<string, boolean>;
  cachedTeams: { id: string; name: string }[];

  oauth(fullRedirect: string, origin: string, isSecondary = false, teamId?: string) {
    const userId = this.authService.userId;
    const tenantId = this.authService.tenantId;
    const config = environment.slack;
    const state = encodeURIComponent(`${fullRedirect}___${origin}___${userId}___${tenantId}___${isSecondary}`);
    const slackRedirectUrl = `${config.oauthURL}?client_id=${this.clientId}&scope=${config.scopes}&user_scope=${config.userScopes}&state=${state}${teamId ? `&team=${teamId}` : ''}`;

    window.open(slackRedirectUrl, '_self');
  }

  userOauth(_fullRedirect: string, origin: string, isSecondary = false, teamId?: string) {
    const userId = this.authService.userId;
    const tenantId = this.authService.tenantId;
    const config = environment.slack;
    const state = encodeURIComponent(
      `${`${window.location.origin}/close-window`}___${origin}___${userId}___${tenantId}___${isSecondary}`,
    );
    const slackRedirectUrl = `${config.oauthURL}?client_id=${this.clientId}&user_scope=${config.userScopes}&state=${state}${teamId ? `&team=${teamId}` : ''}`;

    window.open(slackRedirectUrl, '_blank', 'popup=true');
  }

  async getScopes(teamId?: string): Promise<{ scopes: Record<string, boolean>; exists: boolean }> {
    const { scopes, exists } = (await this.apiService.getPromise(
      `/slack/scopes${teamId ? `?teamId=${teamId}` : ''}`,
    )) as any;

    this.cachedScopes = scopes;

    return { scopes, exists };
  }

  async getUserScopes(teamId?: string): Promise<{ scopes: Record<string, boolean>; exists: boolean }> {
    const { scopes, exists } = (await this.apiService.getPromise(
      `/me/slack/scopes${teamId ? `?teamId=${teamId}` : ''}`,
    )) as any;

    this.cachedUserScopes = scopes;

    return { scopes, exists };
  }

  async requiresScope(refresh?: boolean, teamId?: string): Promise<boolean> {
    const scopes = refresh || !this.cachedScopes ? (await this.getScopes(teamId)).scopes : this.cachedScopes;

    return environment.slack.requiredScopes.some((scope) => !scopes[scope]);
  }

  async requiresUserScope(refresh?: boolean, teamId?: string): Promise<boolean> {
    const scopes =
      refresh || !this.cachedUserScopes ? (await this.getUserScopes(teamId)).scopes : this.cachedUserScopes;

    return environment.slack.requiredUserScopes.some((scope) => !scopes[scope]);
  }

  async hasScope(scope: string, refresh?: boolean, teamId?: string): Promise<boolean> {
    const scopes = refresh || !this.cachedScopes ? (await this.getScopes(teamId)).scopes : this.cachedScopes;

    return !!scopes[scope];
  }

  async hasUserScope(scope: string, refresh?: boolean, teamId?: string): Promise<boolean> {
    const scopes =
      refresh || !this.cachedUserScopes ? (await this.getUserScopes(teamId)).scopes : this.cachedUserScopes;

    return !!scopes[scope];
  }

  async getConnectedChannels(includeShared: boolean = true, teamId?: string): Promise<SlackChannel[]> {
    const query = [];

    if (includeShared) {
      query.push('includeShared=true');
    }

    if (teamId) {
      query.push(`teamId=${teamId}`);
    }

    const channels: SlackChannel[] = await this.apiService.getPromise(
      `/slack/channels${query.length ? `?${query.join('&')}` : ''}`,
    );

    return channels.map((c) => ({
      id: c.id,
      name: c.name,
      displayName: formatChannelName(c),
      isPrivate: c.isPrivate,
      isShared: c.isShared,
      teamId: c.teamId,
    }));
  }

  async getChannel(channelId: string, teamId?: string): Promise<SlackChannel | undefined> {
    const channel: SlackChannel = await this.apiService.getPromise(
      `/slack/channels/${channelId}${teamId ? `?teamId=${teamId}` : ''}`,
    );

    if (!channel) {
      return undefined;
    }

    return {
      id: channel.id,
      name: channel.name,
      displayName: formatChannelName(channel),
      isPrivate: channel.isPrivate,
      isShared: channel.isShared,
      teamId: channel.teamId,
    };
  }

  async getChannelsForUser(includeShared: boolean = true, teamId?: string): Promise<SlackChannel[]> {
    const query = [];

    if (includeShared) {
      query.push('includeShared=true');
    }

    if (teamId) {
      query.push(`teamId=${teamId}`);
    }

    const channels: SlackChannel[] = await this.apiService.getPromise(
      `/me/slack/channels${query.length ? `?${query.join('&')}` : ''}`,
    );

    return channels.map((c) => ({
      id: c.id,
      name: c.name,
      displayName: formatChannelName(c),
      isPrivate: c.isPrivate,
      isShared: c.isShared,
      teamId: c.teamId,
    }));
  }

  async getPublicChannels(includeShared: boolean = true, teamId?: string): Promise<any[]> {
    const query = [];

    if (includeShared) {
      query.push('includeShared=true');
    }

    if (teamId) {
      query.push(`teamId=${teamId}`);
    }

    const channels: SlackChannel[] = await this.apiService.getPromise(
      `/slack/public-channels${query.length ? `?${query.join('&')}` : ''}`,
    );

    return channels.map((c) => ({
      id: c.id,
      name: c.name,
      displayName: formatChannelName(c),
      isPrivate: c.isPrivate,
      isShared: c.isShared,
      teamId: c.teamId,
    }));
  }

  async getPrivateChannels(includeShared: boolean = true, teamId?: string): Promise<SlackChannel[]> {
    const query = [];

    if (includeShared) {
      query.push('includeShared=true');
    }

    if (teamId) {
      query.push(`teamId=${teamId}`);
    }

    const channels: SlackChannel[] = await this.apiService.getPromise(
      `/me/slack/private-channels${query.length ? `?${query.join('&')}` : ''}`,
    );

    return channels.map((c) => ({
      id: c.id,
      name: c.name,
      displayName: formatChannelName(c),
      isPrivate: c.isPrivate,
      isShared: c.isShared,
      teamId: c.teamId,
    }));
  }

  async connectChannel(channelId: string, teamId?: string): Promise<void> {
    await this.apiService.postPromise('/me/slack/connect-channel', { channelId, teamId });
  }

  async syncChannel(channelId: string): Promise<void> {
    await this.apiService.postPromise(`/slack/channels/${channelId}/sync`, {});
  }

  async getConnectedTeams(reload = false): Promise<any[]> {
    if (!reload && this.cachedTeams) {
      return this.cachedTeams;
    }

    this.cachedTeams = await this.apiService.getPromise('/slack/teams');

    return this.cachedTeams;
  }

  disconnect(teamId: string) {
    return this.apiService.postPromise(`/slack/disconnect/${teamId}`, {});
  }

  disconnectUser() {
    this.apiService.postPromise('/slack/disconnect-user', {});
  }
}
